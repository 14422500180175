import React from 'react';
import { Col, Container, Row, Image, Table } from 'react-bootstrap';
import HomeSources from './HomeArchitectures/HomeSources';
import TableFormats from './HomeArchitectures/TableFormats';
import DataDiscovery from './HomeArchitectures/DataDiscovery';
import CombinedSources from './HomeArchitectures/CombinedSources';
import CombinedTableFormats from './HomeArchitectures/CombinedTableFormats';
import CombinedDataDiscovery from './HomeArchitectures/CombinedDataDiscovery';

function Home() {
    return (
        <>
            <Container className='mt-3'>
                <div>
                    <p className='fs-1'>
                        Avocado
                        <span className='custom-font-color'> Datalake</span>
                    </p>
                    <div className='fw-normal'>
                        <strong>
                            <h3>
                                Avocado Datalake simplifies data management for
                                your organization.
                            </h3>
                        </strong>
                        <p>
                            We are a data lake consultancy specializing in
                            transforming raw data into actionable insights. Our
                            end-to-end solutions encompass data ingestion,
                            storage, management, and discovery. We seamlessly
                            integrate data from diverse sources including{' '}
                            <a href='https://www.mysql.com/' target='_blank'>
                                MySQL
                            </a>
                            ,{' '}
                            <a
                                href='https://aws.amazon.com/rds/aurora/'
                                target='_blank'
                            >
                                Amazon Aurora
                            </a>
                            ,{' '}
                            <a
                                href='https://cloud.google.com/sql'
                                target='_blank'
                            >
                                Cloud SQL
                            </a>
                            ,{' '}
                            <a
                                href='https://cloud.google.com/spanner'
                                target='_blank'
                            >
                                Spanner
                            </a>
                            , and{' '}
                            <a href='https://kafka.apache.org/' target='_blank'>
                                Apache Kafka
                            </a>{' '}
                            and{' '}
                            <a href='https://www.mongodb.com/' target='_blank'>
                                MongoDB
                            </a>{' '}
                            into centralized repository (data lake) based on the
                            cloud storage such as AWS S3 or Google Cloud
                            Storage. By leveraging{' '}
                            <a href='https://hudi.apache.org/' target='blank'>
                                Apache Hudi
                            </a>
                            ,{' '}
                            <a href='https://delta.io/' target='blank'>
                                Delta lake
                            </a>{' '}
                            or{' '}
                            <a
                                href='https://iceberg.apache.org/'
                                target='blank'
                            >
                                Apache Iceberg
                            </a>{' '}
                            as an open table storage format that will ensure CDC
                            capture and read and write operations.
                        </p>
                        <p>
                            To maximize the value of your data lake, we
                            implement advanced metadata management using{' '}
                            <a
                                href='https://docs.aws.amazon.com/glue/latest/dg/components-overview.html#data-catalog-intro'
                                target='_blank'
                            >
                                AWS Glue Data Catalog
                            </a>
                            ,{' '}
                            <a
                                href='https://www.databricks.com/product/unity-catalog'
                                target='_blank'
                            >
                                Unity Catalog
                            </a>
                            , or{' '}
                            <a
                                href='https://cloud.google.com/data-catalog/docs/concepts/overview'
                                target='_blank'
                            >
                                {' '}
                                GCP Data Catalog
                            </a>
                            . This enables seamless data discovery and analysis
                            through tools like{' '}
                            <a
                                href='https://aws.amazon.com/athena/'
                                target='_blank'
                            >
                                Amazon Athena
                            </a>
                            ,{' '}
                            <a href='https://prestodb.io/' target='_blank'>
                                Presto
                            </a>
                            ,{' '}
                            <a
                                href='https://airflow.apache.org/'
                                target='_blank'
                            >
                                Apache Airflow
                            </a>
                            ,{' '}
                            <a
                                href='https://lookerstudio.google.com/'
                                target='_blank'
                            >
                                Looker and Looker Studio
                            </a>
                            . Our expertise extends to data governance and
                            security, providing best practices for table access
                            and permissions using{' '}
                            <a
                                href='https://aws.amazon.com/lake-formation/'
                                target='_blank'
                            >
                                AWS Lake Formation
                            </a>
                            .
                        </p>
                        <p>
                            Further more we can connect your data lake storage
                            data into enterprise data warehouse such as{' '}
                            <a
                                href='https://aws.amazon.com/redshift/'
                                target='_blank'
                            >
                                Amazon Redshift
                            </a>{' '}
                            and{' '}
                            <a
                                href='https://cloud.google.com/bigquery'
                                target='_blank'
                            >
                                BigQuery
                            </a>
                        </p>
                        <p>
                            <h5>
                                We partner with organizations to unlock the full
                                potential of their data and drive data-driven
                                decision making.
                            </h5>
                        </p>
                    </div>
                </div>
            </Container>
            <Container className='my-4'>
                <p className='fs-1'>
                    Avocado
                    <span className='custom-font-color'>
                        {' '}
                        Datalake Architecture
                    </span>
                </p>
                <p className='fw-normal'>
                    A high level design architecture of our propose solution for
                    your organization to manage all sources of data into a
                    unified data lake.
                </p>

                <Row>
                    <Col xs={6} md={4}>
                        {/* <HomeSources /> */}
                        <CombinedSources />
                        {/* <span className='my-4'>
                            Any source can be easily accomodated for ingestion
                        </span> */}
                    </Col>

                    <Col xs={6} md={4}>
                        {/* <TableFormats /> */}
                        <CombinedTableFormats />
                        {/* <strong className='my-4'>
                            Any table format be switch easily in our pre build
                            codebase
                        </strong> */}
                    </Col>
                    <Col xs={6} md={4}>
                        {/* <DataDiscovery /> */}
                        <CombinedDataDiscovery />
                        {/* <strong>
                            We can attached any Data Discovery tools to our Data
                            lake though hive style metadata cataloging
                        </strong> */}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={4}>
                        {/* <HomeSources /> */}
                        {/* <CombinedSources /> */}
                        <span>
                            Any source can be easily accomodated for ingestion
                        </span>
                    </Col>

                    <Col xs={6} md={4}>
                        {/* <TableFormats /> */}
                        {/* <CombinedTableFormats /> */}
                        <span>
                            Any table format be switch easily in our pre build
                            codebase
                        </span>
                    </Col>
                    <Col xs={6} md={4}>
                        {/* <DataDiscovery /> */}
                        {/* <CombinedDataDiscovery /> */}
                        <span>
                            We can attached any Data Discovery tools to our Data
                            lake though hive style metadata cataloging
                        </span>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Home;
