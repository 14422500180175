import { Container } from 'react-bootstrap';

function Footer() {
    return (
        <footer className='bg-primary text-white text-center py-3'>
            <Container>
                <p>© Avocado Datalake 2024 </p>
            </Container>
        </footer>
    );
}

export default Footer;
