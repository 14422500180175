import { Container, Figure } from 'react-bootstrap';

import data_driven_approach from '../assets/images/DataDrivenApproach_3.png';
import ConpyContactUsEmail from './CopyContactUsEmail';

function AboutUs() {
    return (
        <Container>
            <header>
                <h1>About Us</h1>
            </header>
            <p className='lead'>
                Empowering Data-Driven Success with expertise in Data
                Engineering
            </p>
            <div className='d-flex justify-content-center'>
                <Figure className='figure-img img-fluid'>
                    <Figure.Image
                        alt='Data driven aproach'
                        src={data_driven_approach}
                    />
                </Figure>
            </div>
            <div className='d-flex justify-content-center'>
                <Figure.Caption>
                    Is your company looking to transform OLTP data into an OLAP
                    system or a centralized repository on cloud storage like AWS
                    S3 or GCP GCS, also known as a data lake? We’re the perfect
                    partner for you. Contact us at the email address below:
                </Figure.Caption>
            </div>

            <ConpyContactUsEmail />

            <p className='lead'>
                We are certified Data Engineer from AWS, GCP and holding the
                certification of{' '}
                <a
                    href='https://aws.amazon.com/certification/certified-data-engineer-associate/'
                    target='blank'
                >
                    AWS Certified Data Engineer - Associate
                </a>{' '}
                and{' '}
                <a
                    href='https://cloud.google.com/learn/certification/data-engineer'
                    target='blank'
                >
                    Professional Data Engineer
                </a>
                , Which help your organizations to quickly fullfil the
                requirements of desing complete ETL pipeline by following the
                best industry practice in Data Engineering.
            </p>

            <div className='my-4'>
                <header className='my-4'>
                    <h2>Our Expertise in Data Engineering:</h2>
                </header>
                As a specialized data engineer and specialy in managing the
                large scale data lake, we are committed to delivering
                comprehensive solutions that not only unlock the potential of
                your data but also ensure its security and compliance. Our data
                governance and engineering expertise empowers organizations to
                make informed decisions while upholding the highest data
                protection standards.
                <ul className='my-4'>
                    <li>
                        <strong>Scalable Data Pipelines:</strong>{' '}
                        <span>
                            We design and build high-performance data pipelines
                            using Apache Spark and Apache Flink frameworks,
                            leveraging Scala or Python to efficiently handle
                            large volumes of data, supporting both batch and
                            stream processing.
                        </span>
                    </li>
                    <li>
                        <strong>ETL/ELT Implementation:</strong>{' '}
                        <span>
                            We manage all transformation configurations through
                            YAML files for each table-level ingestion, allowing
                            for more precise and actionable transformations on
                            row data. This method improves the overall usability
                            of the data lake.
                        </span>
                    </li>
                    <li>
                        <strong>Data Lake Optimization:</strong>{' '}
                        <span>
                            We optimize data lake performance and
                            cost-effectiveness by fine-tuning the storage
                            settings of Parquet files. This ensures that the
                            files are neither too small nor too large—avoiding
                            excessive costs associated with ingestion or data
                            retrieval using Athena or other SQL-based
                            interfaces. For more information, check out our
                            technical blogs.
                        </span>
                    </li>
                    <li>
                        <strong>Cloud-Native Technologies:</strong>{' '}
                        <span>
                            We utilize cloud-native technologies and serverless
                            architectures for enhanced scalability and
                            cost-efficiency. For example, we use AWS Lambda to
                            sync metadata, even across different AWS accounts.
                            To orchestrate workflows, we combine AWS Step
                            Functions, AWS Lambda, and AWS EventBridge to
                            automate data ingestion from various sources.
                        </span>
                    </li>
                    <li>
                        <strong>Robust Data Management:</strong>{' '}
                        <span>
                            We implement robust data governance frameworks to
                            ensure data ownership, quality, and security. When
                            connecting to the data catalog, we always use AWS
                            Lake Formation to maintain data lake security and
                            enable fine-grained access control, including
                            row-level and column-level filtering.
                        </span>
                    </li>
                    <li>
                        <strong>Robust Data Management:</strong>{' '}
                        <span>
                            We integrate with centralized data catalogs to
                            streamline data discovery and utilization. e.g.{' '}
                            <a
                                href='https://docs.aws.amazon.com/glue/latest/dg/catalog-and-crawler.html'
                                target='_blank'
                            >
                                AWS Glue Data catalog
                            </a>{' '}
                            and{' '}
                            <a
                                href='https://cloud.google.com/data-catalog/docs/concepts/overview'
                                target='_blank'
                            >
                                GCP Data Catalog
                            </a>
                        </span>
                    </li>
                    <li>
                        <strong>Data Lineage and Impact Analysis:</strong>{' '}
                        <span>
                            We provide tools and processes to track data lineage
                            and assess the impact of changes.
                        </span>
                    </li>
                </ul>
            </div>

            <div className='lead my-4'>
                By combining our data governance and data engineering expertise,
                we help organizations establish a solid foundation for
                data-driven initiatives while mitigating risks and ensuring
                compliance.
            </div>

            <ConpyContactUsEmail />
        </Container>
    );
}

export default AboutUs;
