import { Container } from 'react-bootstrap';

import ConpyContactUsEmail from './CopyContactUsEmail';

function ContactUs() {
    return (
        <Container className='mt-3'>
            <header>
                <h1>Contact Us</h1>
            </header>
            <p className='lead'>
                Is your company considering a data-driven approach? You're in
                the right place. Contact us to set up a centralized data
                repository i.e called as data lake on any cloud platform like
                AWS S3, GCP GCS and seamlessly connect this data lake with data
                catalog like AWS Glue, Hive meta data, enabling your teams -
                AI/ML, Data Insights, Business Intelligence, and more to
                leverage it for informed decision-making.
            </p>

            <ConpyContactUsEmail />

            <div className='my-4'>
                We accelerate your data lake implementation by providing
                pre-built code to construct robust ETL pipelines. We can ingest
                data from multiple sources into your preferred cloud storage
                (AWS S3, Google Cloud Storage, or Azure Blob Storage) using the
                below table formats:
                <ul>
                    <li>
                        <a href='https://hudi.apache.org/' target='blank'>
                            Apache Hudi
                        </a>
                    </li>
                    <li>
                        <a href='https://delta.io/' target='blank'>
                            Delta lake
                        </a>
                    </li>
                    <li>
                        <a href='https://iceberg.apache.org/' target='blank'>
                            Apache Iceberg
                        </a>
                    </li>
                </ul>
                These open storage table format enabled us to empower the
                capability of
                <ul>
                    <li>ACID like properties in data lake.</li>
                    <li>record-level updates</li>
                    <li>record-leveld deletes</li>
                    <li>CDC will be easiler</li>
                </ul>
            </div>

            <div className='my-4'>
                We specialize in data storage on above table formats and enabled
                its discovery through data catalog so the you can enable the
                power of AWS Athena, Pestro like interface to connect and do the
                insights things very quickly.
            </div>
            <ConpyContactUsEmail />
        </Container>
    );
}

export default ContactUs;
