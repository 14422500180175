import ImageSwapper from '../ImageSwapper';

import source_MySQL from '../../assets/sources/source_MySQL.png';
import source_aurora_db from '../../assets/sources/source_aws_aurora_db.png';
import source_rds_db from '../../assets/sources/source_aws_rds_db.png';
import dynamodb from '../../assets/sources/source_dynamodb.png';
import mongodb from '../../assets/sources/source_mongodb.png';
import parquet from '../../assets/sources/source_parquet.png';
import csv from '../../assets/sources/source_csv.png';
import json from '../../assets/sources/source_json.jpeg';

function HomeSources() {
    const images = [
        source_MySQL,
        source_aurora_db,
        source_rds_db,
        dynamodb,
        mongodb,
        parquet,
        csv,
        json,
    ];

    // const imagesCarousel = [
    //     {source_MySQL, 1},
    //     {source_aurora_db},
    //     source_rds_db,
    //     dynamodb,
    //     mongodb,
    //     parquet,
    //     csv,
    //     json,
    // ];

    return (
        <>
            <h1>sources</h1>
            <div>
                <ImageSwapper
                    images={images}
                    alt='Source can be MySQL, Aurora DB, DynamoDB, MongoDB, Kafka, CSV, JSON, Parquet etc.'
                    caption='Any sources any be easy accomodated'
                />

                {/* Need more investigation for resizing of images */}
                {/* <ImageCarousel
                    images={images}
                    alt='Source can be MySQL, Aurora DB, DynamoDB, MongoDB, Kafka, CSV, JSON, Parquet etc.'
                    caption='Any sources any be easy accomodated'
                /> */}
            </div>
        </>
    );
}

export default HomeSources;
