import { useEffect } from 'react';

const HandleFavicon = () => {
    useEffect(() => {
        const setFavicon = (size) => {
            const favicon =
                document.querySelector('link[rel="icon"]') ||
                document.createElement('link');
            favicon.type = 'image/png';
            favicon.rel = 'icon';

            if (size === 'small') {
                favicon.href = `${process.env.PUBLIC_URL}/favicon.png`;
            } else if (size === 'medium') {
                favicon.href = `${process.env.PUBLIC_URL}/logo192.png`;
            } else {
                favicon.href = `${process.env.PUBLIC_URL}/logo512.png`;
            }

            document.getElementsByTagName('head')[0].appendChild(favicon);
        };
        const smallScreen = window.matchMedia('(max-width: 600px)');
        const mediumScreen = window.matchMedia(
            '(min-width: 601px) and (max-width: 1200px)',
        );

        const updateFavicon = () => {
            if (smallScreen.matches) {
                setFavicon('small');
            } else if (mediumScreen.matches) {
                setFavicon('medium');
            } else {
                setFavicon('large');
            }
        };

        // Initial favicon setup
        updateFavicon();

        // Add listeners for screen size changes
        smallScreen.addListener(updateFavicon);
        mediumScreen.addListener(updateFavicon);

        return () => {
            smallScreen.removeListener(updateFavicon);
            mediumScreen.removeListener(updateFavicon);
        };
    }, []);
};

export default HandleFavicon;
