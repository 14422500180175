import ImageSwapper from '../ImageSwapper';

import airflow from '../../assets/discovery/discovery_apache_airflow.png';
import athena from '../../assets/discovery/discovery_aws_athena_1.jpeg';
import dbt from '../../assets/discovery/discovery_dbt.png';
import hive from '../../assets/discovery/discovery_hive.png';
import looker from '../../assets/discovery/discovery_looker.png';
import looker_studio from '../../assets/discovery/discovery_looker_studio.jpeg';
import prestro from '../../assets/discovery/discovery_presto.png';
import quicksight from '../../assets/discovery/discovery_quicksight.png';
import trino from '../../assets/discovery/discovery_trino.png';

function DataDiscovery() {
    const images = [
        airflow,
        athena,
        dbt,
        hive,
        looker,
        looker_studio,
        prestro,
        quicksight,
        trino,
    ];

    return (
        <>
            <h1>Data Discovery</h1>
            <ImageSwapper
                images={images}
                alt='Athena, Presto, Trino, Airflow, dbt etc'
                caption='Data lake can be easily connected to any data discovery tools like Athena, Airflow, dbt, trino, presto etc'
            />
        </>
    );
}

export default DataDiscovery;
