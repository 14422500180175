import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

function Header() {
    return (
        <Navbar sticky='top' expand='lg' bg='primary' data-bs-theme='dark'>
            <Container>
                <Navbar.Brand href='/'>Datalake</Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='me-auto'>
                        <Nav.Link href='/about-us' className='nav-item'>
                            About Us
                        </Nav.Link>
                        <Nav.Link href='/contact-us' className='nav-item'>
                            Contact Us
                        </Nav.Link>
                        <NavDropdown title='Tech Blogs' id='basic-nav-dropdown'>
                            {/* item 1 of dropdown */}
                            <NavDropdown.Item
                                href='/contact-us'
                                className='nav-item'
                            >
                                Contact Us
                            </NavDropdown.Item>
                            {/* item 2 of dropdown */}
                            <NavDropdown.Item href='/about-us'>
                                About Us
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
