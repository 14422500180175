import table_format_apache_hudi from '../../assets/table_formats/table_formats_apache_hudi.png';
// import table_format_apache_hudi from '../../assets/table_formats/groups/table_formats_apache_hudi_100.png';

import table_format_deltalake from '../../assets/table_formats/table_formats_deltalake.png';
// import table_format_deltalake from '../../assets/table_formats/groups/table_formats_deltalake_100.png';

import table_format_iceberg from '../../assets/table_formats/table_formats_apache_iceberg.png';
import { Col, Container, Image, Row } from 'react-bootstrap';
// import table_format_iceberg from '../../assets/table_formats/groups/table_formats_apache_iceberg_100.png';

// import hudi_deltalake_iceberg from '../../assets/table_formats/table_formats_hudi_deltalake_iceberg.png';

function CombinedTableFormats() {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h1>Table Formats</h1>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col sm>
                        <Image
                            src={table_format_apache_hudi}
                            alt='table format Apache Hudi'
                        />
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col sm>
                        <Image
                            src={table_format_deltalake}
                            alt='Table format Deltalake from Databricks'
                        />
                    </Col>
                </Row>

                <Row className='my-4'>
                    <Col sm>
                        <Image
                            src={table_format_iceberg}
                            alt='Table format Apache Iceberg'
                        />
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                </Row>
                <Row></Row>
            </Container>
        </>
    );
}

export default CombinedTableFormats;
