import athena from '../../assets/discovery/groups/aws_athena_screenshot_100.png';
import quicksight from '../../assets/discovery/groups/aws_quicksights_screenshot_100.png';

import prestro from '../../assets/discovery/groups/discovery_presto_100.png';
import trino from '../../assets/discovery/groups/discovery_trino_100.png';

import dbt from '../../assets/discovery/groups/discovery_dbt_1.png';
import hive from '../../assets/discovery/groups/discovery_hive_100.png';

import looker from '../../assets/discovery/groups/Looker_logo_horizental_.jpg';

import { Col, Container, Image, Row } from 'react-bootstrap';

function CombinedDataDiscovery() {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h1>Data Discovery</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image
                            src={athena}
                            alt='Data Discovery in AWS Athena Airflow'
                        />
                    </Col>
                    <Col>
                        <Image
                            src={quicksight}
                            alt='Data Discovery in  Apache Airflow'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image
                            src={trino}
                            alt='Data Discovery in  Deltalake from Databricks'
                        />
                    </Col>
                    <Col>
                        <Image
                            src={prestro}
                            alt='Data Discovery in Deltalake from Databricks'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image
                            src={dbt}
                            alt='Data Discovery in  Deltalake from Databricks'
                        />
                    </Col>
                    <Col>
                        <Image
                            src={hive}
                            alt='Data Discovery in Deltalake from Databricks'
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Image
                            src={looker}
                            alt='Data Discovery in  Deltalake from Databricks'
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CombinedDataDiscovery;
