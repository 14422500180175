import { useState } from 'react';
import {
    Button,
    Container,
    Form,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';

function ConpyContactUsEmail() {
    const [showTooltip, setShowTooltip] = useState(false);
    const emailAddress = 'avocado.datalake@gmail.com';

    const handleCopy = () => {
        navigator.clipboard.writeText(emailAddress).then(() => {
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000); // Hide the tooltip after 2 seconds
        });
    };

    return (
        <Container>
            <div className='my-4 d-flex justify-content-center align-items-center'>
                <Form.Label className='mb-3 pe-2'>Email us at: </Form.Label>{' '}
                <InputGroup className='mb-3' style={{ maxWidth: '400px' }}>
                    <FormControl
                        readOnly
                        value={emailAddress}
                        aria-label='Email address'
                    />
                    <OverlayTrigger
                        placement='top'
                        show={showTooltip}
                        overlay={<Tooltip id='button-tooltip'>Copied!</Tooltip>}
                    >
                        <Button variant='primary' onClick={handleCopy}>
                            Copy Email
                        </Button>
                    </OverlayTrigger>
                </InputGroup>
            </div>
        </Container>
    );
}

export default ConpyContactUsEmail;
