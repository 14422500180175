import source_MySQL from '../../assets/sources/groups/source_MySQL_width_100.png';
import source_aurora_db from '../../assets/sources/groups/aws_aurora_db_100_1.png';

import parquet from '../../assets/sources/groups/source_parquet_width_100_2.jpeg';
import kafka from '../../assets/sources/groups/source_kafka_width_100.png';

import gcp_cloud_sql from '../../assets/sources/groups/CloudSQL.png';
import gcp_spanner from '../../assets/sources/groups/CloudSpanner.png';

import csv from '../../assets/sources/groups/source_csv_width_100.png';
import json from '../../assets/sources/groups/source_json_width_100.jpeg';

import { Col, Container, Image, Row } from 'react-bootstrap';

function CombinedSources() {
    return (
        <>
            <Container>
                <Row>
                    <Col sm={2}></Col>
                    <Col>
                        <h1>Sources</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Image src={source_MySQL} alt='source MySQL' />
                    </Col>
                    <Col sm>
                        <Image
                            src={source_aurora_db}
                            alt='source AWS Aurora DB'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Image src={gcp_cloud_sql} alt='source GCP Cloud SQL' />
                    </Col>
                    <Col sm>
                        <Image
                            src={gcp_spanner}
                            alt='source GCP Cloud Spanner'
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm>
                        <Image src={parquet} alt='source Parquet files' />
                    </Col>
                    <Col>
                        <Image src={kafka} alt='source can be Kafka' />
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Image
                            src={json}
                            alt='source json files on S3 or GCS'
                        />
                    </Col>
                    <Col sm>
                        <Image src={csv} alt='source csv on S3 or GCS' />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CombinedSources;
