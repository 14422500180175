import './App.css';
import { Route, Routes } from 'react-router-dom';

import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import HandleFavicon from './components/HandleFavicon';

function App() {
    HandleFavicon();
    return (
        <div>
            <Header />
            <main>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                </Routes>
            </main>
            <Footer />
        </div>
    );
}

export default App;
